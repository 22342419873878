import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import { routeList } from "./helpers/constants";
import './index.css';
import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from '../src/helpers/i18n';
import { PageNotFound } from "./components/error/PageNotFound";
import { Home } from "./components/home/Home";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { ApplyCreme } from "./components/apply/ApplyCreme";
import reportWebVitals from './reportWebVitals';
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap komponente

const App = () => {
    const { t } = useTranslation();
    return (
        <BrowserRouter>
            <Container fluid>
                <Header />
                <Row>
                    <Col xs={12}>
                        <Routes>
                            <Route
                                path={routeList.homePage}
                                element={<Home />}
                            />
                            <Route
                                path={routeList.applyCreme}
                                element={<ApplyCreme />}
                            />
                            <Route
                                path={routeList.gallery}
                                element={<PageNotFound textError={`${t("pageNotFinishedYetError")}`} errorButton={`${t("pageNotFinishedYetButton")}`} />}
                            />
                            <Route
                                path={routeList.contact}
                                element={<PageNotFound textError={`${t("pageNotFinishedYetError")}`} errorButton={`${t("pageNotFinishedYetButton")}`} />}
                            />
                            <Route
                                path={routeList.noDeepLinkUrl}
                                element={<Navigate to={routeList.homePage} replace />}
                            />
                            <Route
                                path={routeList.other}
                                element={<PageNotFound textError={`${t("pageNotFoundError")}`} errorButton={`${t("pageNotFinishedYetButton")}`} />}
                            />
                        </Routes>
                    </Col>
                </Row>
                <Footer />
            </Container>
        </BrowserRouter>
    );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
