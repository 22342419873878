import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './PageNotFound.css';

export const PageNotFound = props => {
    return (
        <div className="not-found-container d-flex justify-content-center align-items-center">
            <Row className="text-center">
                <Col>
                    <div className="not-found-content">
                        <h1 className="error-code">404</h1>
                        <h2 className="error-message">Oops! Page Not Found</h2>
                        <p className="error-description">
                            {props.textError}
                        </p>
                        <Button href="/" variant="primary" className="home-link">
                            {props.errorButton}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
