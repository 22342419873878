// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationSR from '../locales/sr/translation.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN,
            },
            sr: {
                translation: translationSR,
            },
        },
        lng: 'sr',
        fallbackLng: 'sr',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
