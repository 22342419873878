import "./Footer.css";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHome, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();
    return (
        <footer>
            <div className="row">
                <Row className="mb-4 text-center">
                    <Col>
                        <p>{t("footerSocialNetworks")}</p>
                        <div>
                            <Link to="https://x.com" className="social-icon">
                                <FontAwesomeIcon icon={faXTwitter} size="2x"/>
                            </Link>
                            <Link to="https://www.instagram.com" className="social-icon">
                                <FontAwesomeIcon icon={faInstagram} size="2x"/>
                            </Link>
                            <Link to="https://www.facebook.com/" className="social-icon">
                                <FontAwesomeIcon icon={faFacebook} size="2x"/>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <Row className="text-center">
                    <Col md={6} className="mb-3 mb-md-0">
                        <h5>{t('footerAboutUs')}</h5>
                        <p className="aboutUsFooterParagraph">
                            {t('footerMessageAboutUs')}
                        </p>
                    </Col>
                    <Col md={6}>
                        <h5>{t('footerContact')}</h5>
                        <p>
                            <FontAwesomeIcon icon={faHome}/> Jurija Gagarina, 11070, Novi Beograd
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faEnvelope}/>{' '}
                            <a href="mailto:v.careproducts@yahoo.com" className="contact-link">
                                v.careproducts@yahoo.com
                            </a>
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faPhone}/> +321 23 45 657
                        </p>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col md={12} className="mb-3 mb-md-0">
                        <div className='text-center p-4' style={{backgroundColor: 'rgba(0, 0, 0, 0.01)'}}>
                            © {currentYear} Copyright: <b>Vitrorem</b>
                        </div>
                    </Col>
                </Row>
            </div>
        </footer>
    );
};
