import "./Header.css";
import React from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LanguageSelector } from "../../helpers/LanguageSelector";
import { useTranslation } from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();
    return (
        <Navbar bg="light" expand="lg" className="header">
            <Navbar.Brand as={Link} to="/home" className="d-flex align-items-center companyLink">
                <img src={logo} alt="Company Logo" />
            </Navbar.Brand>
            <div className="d-flex align-items-center">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-2" />
                <LanguageSelector className="ms-3" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav>
                    <Nav.Link as={Link} to="/home">{t("linkHome")}</Nav.Link>
                    <Nav.Link as={Link} to="/apply">{t("linkApply")}</Nav.Link>
                    <Nav.Link as={Link} to="/gallery">{t("linkGallery")}</Nav.Link>
                    <Nav.Link as={Link} to="/contact">{t("linkContact")}</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
