import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importovanje Bootstrap CSS-a
import './Home.css';
import {useTranslation} from "react-i18next"; // Uvozimo našu CSS datoteku

export const Home = () => {
    const { t } = useTranslation();
    return (
        <div className="home">
            <Row className="justify-content-center align-items-center position-relative">
                <Col xs={12}>
                    <h1 className="title">VITROREM</h1>
                    <h2 className="subtitle">{t("homeSubtitle")}</h2>
                    <p className="section-text">{t("homeSectionText1")}</p>
                    <h3 className="section-heading">{t("homeSectionHeading1")}</h3>
                    <ul className="section-text list">
                        <li>
                            <ul>
                                <li>{t("homeSectionText2.1")}</li>
                                <li>{t("homeSectionText2.2")}</li>
                                <li>{t("homeSectionText2.3")}</li>
                                <li>{t("homeSectionText2.4")}</li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="section-heading">{t("homeSectionHeading2")}</h3>
                    <p className="section-text">{t("homeSectionText3")}</p>
                    <h3 className="section-heading">{t("homeSectionHeading3")}</h3>
                    <p className="section-text">{t("homeSectionText4")}</p>
                    <h3 className="section-heading warning">{t("homeSectionHeading4")}</h3>
                    <p className="section-text warning">{t("homeSectionTextWarning")}</p>
                </Col>
            </Row>
        </div>
    );
};
