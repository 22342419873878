import "./ApplyCreme.css";
import applyCreme from "../../images/applyCreme.jpg";

export const ApplyCreme = () => {
  return(
      <div className="apply">
          <img
              src={ applyCreme }
              alt="Apply creme"
              className="applyCreme"
          />
      </div>
  )
}
