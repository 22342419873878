import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as SrFlagIcon } from '../images/srb.svg';
import { ReactComponent as EnFlagIcon } from '../images/eng.svg';
import './LanguageSelector.css';

export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const changeLanguage = async (lng) => {
        await i18n.changeLanguage(lng);
    };

    const getFlagIcon = (language) => {
        switch (language) {
            case 'en':
                return <EnFlagIcon width={20} height={20} />;
            case 'sr':
                return <SrFlagIcon width={20} height={20} />;
            default:
                return <SrFlagIcon width={20} height={20} />;
        }
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
                <div className="dropdown-toggle-content">
                    {getFlagIcon(currentLanguage)}
                    <span className="language-text">
                        {currentLanguage === 'en' ? 'ENG' : 'SRP'}
                    </span>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage('en')}>
                    <div className="dropdown-toggle-content">
                        <EnFlagIcon width={20} height={20} />
                        <span className="language-text">English</span>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('sr')}>
                    <div className="dropdown-toggle-content">
                        <SrFlagIcon width={20} height={20} />
                        <span className="language-text">Srpski</span>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
